import React from 'react';
import Footer from '../../components/Footer';
import { Mail } from 'lucide-react';

function ContactPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white">
      <div className="max-w-3xl mx-auto px-4 py-16">
        <div className="bg-white rounded-2xl shadow-lg p-8 text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">Contact Us</h1>
          
          <div className="flex items-center justify-center mb-6">
            <Mail className="w-6 h-6 text-navy-600 mr-2" />
            <a 
              href="mailto:support@tipd.xyz" 
              className="text-xl text-navy-600 hover:text-navy-700 transition-colors"
            >
              support@tipd.xyz
            </a>
          </div>
          
          <p className="text-gray-600">
            We're here to help! Send us an email and we'll get back to you as soon as possible.
          </p>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default ContactPage;