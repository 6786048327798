import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle, AlertCircle, Clock } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

function PaymentSetupSuccessPage() {
  const navigate = useNavigate();
  const { user, updateUserData } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [verificationState, setVerificationState] = useState<'verifying' | 'verified' | 'failed'>('verifying');
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    const verifyStripeStatus = async () => {
      if (!user?.uid || !user?.stripeAccountId) return;

      try {
        const response = await fetch('/.netlify/functions/account-status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ accountId: user.stripeAccountId })
        });

        if (!response.ok) {
          throw new Error('Failed to verify Stripe account status');
        }

        const { isEnabled, detailsSubmitted } = await response.json();
        
        if (!isEnabled || !detailsSubmitted) {
          setError('Stripe account setup is incomplete. Please complete all required steps.');
          setVerificationState('failed');
          return;
        }

        // Update Firebase directly
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, {
          stripeEnabled: isEnabled,
          stripeOnboardingStatus: detailsSubmitted ? 'completed' : 'pending',
          updatedAt: new Date().toISOString()
        });

        // Update local user data
        updateUserData({ 
          stripeEnabled: isEnabled,
          stripeOnboardingStatus: detailsSubmitted ? 'completed' : 'pending'
        });

        setVerificationState('verified');
        setShowSuccess(true);
      } catch (error) {
        console.error('Error verifying Stripe status:', error);
        setError('Failed to verify Stripe account status. Please try again.');
        setVerificationState('failed');
      }
    };

    verifyStripeStatus();
  }, [user?.uid, user?.stripeAccountId]);

  // Handle navigation after successful verification
  useEffect(() => {
    if (verificationState === 'verified' && showSuccess) {
      const timer = setTimeout(() => {
        if (user?.username) {
          navigate(`/${user.username}`);
        } else {
          navigate('/');
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [verificationState, showSuccess, navigate, user?.username]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          className="text-center max-w-md"
        >
          <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-6" />
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Setup Incomplete</h1>
          <p className="text-gray-600 mb-8">{error}</p>
          <button
            onClick={() => navigate('/payment-setup')}
            className="px-6 py-3 bg-navy-600 text-white rounded-lg hover:bg-navy-700 transition-colors"
          >
            Try Again
          </button>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="text-center max-w-md"
      >
        {verificationState === 'verifying' ? (
          <div className="text-center">
            <div className="text-yellow-500 mb-4">
              <Clock className="w-12 h-12 mx-auto" />
            </div>
            <h1 className="text-xl font-bold text-gray-900 mb-4">Verifying Setup</h1>
            <p className="text-gray-600 mb-6">Checking your account status...</p>
          </div>
        ) : verificationState === 'verified' && showSuccess ? (
          <>
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              className="mb-6"
            >
              <CheckCircle className="w-16 h-16 text-green-500 mx-auto" />
            </motion.div>
            
            <h1 className="text-2xl font-bold text-gray-900 mb-4">
              Setup Complete!
            </h1>
            
            <p className="text-gray-600 mb-8">
              Your account is now ready to receive tips through tipd. You'll be redirected to your profile in a moment.
            </p>
          </>
        ) : null}
      </motion.div>
    </div>
  );
}

export default PaymentSetupSuccessPage;