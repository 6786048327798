import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import StripeConnect from '../StripeConnect';
import Footer from '../Footer';

function PaymentSetupPage() {
  const navigate = useNavigate();
  const { user, loading } = useAuth();

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login', { replace: true });
    }
  }, [user, loading, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-navy-600 border-t-transparent"></div>
      </div>
    );
  }

  if (!user) return null;

  return (
    <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white">
      <div className="max-w-3xl mx-auto px-4 py-16">
        <Link
          to={`/${user.username}`}
          className="inline-flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-4"
        >
          <ArrowLeft className="w-4 h-4" />
          Back to Profile
        </Link>

        <div className="bg-white rounded-2xl shadow-lg p-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Payment Setup</h1>
          
          <div className="space-y-4 text-gray-600 mb-8">
            <p>
              To receive tips through tipd, you'll need to complete a simple payment setup process through our secure payment partner, Stripe.
            </p>
            
            <p>
              You'll be asked to provide:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Basic business or personal information</li>
              <li>Your bank account details for receiving payments</li>
              <li>A valid form of identification for verification</li>
            </ul>

            <p>
              All information is securely handled by Stripe, and your banking details are never stored on our servers. Tips you receive will be automatically transferred to your connected bank account.
            </p>

            <p className="text-sm">
              By proceeding with the payment setup, you agree to our <Link to="/terms" className="text-navy-600 hover:text-navy-700 underline">Terms of Service</Link> and Stripe's Connected Account Agreement.
            </p>
          </div>

          <StripeConnect />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PaymentSetupPage;
