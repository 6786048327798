import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDown } from 'lucide-react';
import Footer from '../../components/Footer';

function HelpPage() {
  const faqs = [
    {
      category: "Getting Started",
      questions: [
        {
          question: "How do I create a tipd account?",
          answer: "Creating a tipd account is easy! Click the 'Sign up' button, enter your email and password, choose a username, and you're ready to go. Make sure to complete your profile setup to start receiving tips."
        },
        {
          question: "Do I need to set up Stripe to receive tips?",
          answer: "Yes, you'll need to complete Stripe onboarding to receive tips. This ensures secure and reliable payment processing. After signing up, click 'Set Up Payments' on your profile to begin the Stripe connection process."
        },
        {
          question: "How do I customize my profile?",
          answer: "Once logged in, visit your profile page to add a profile picture, write a bio, and set your preferred tip amounts. These customizations help make your profile more engaging for potential supporters."
        }
      ]
    },
    {
      category: "Receiving Tips",
      questions: [
        {
          question: "How quickly will I receive my tips?",
          answer: "Tips are processed through Stripe and typically appear in your connected bank account within 2-3 business days. You can track your pending and available balances in your Stripe dashboard."
        },
        {
          question: "Are there any fees?",
          answer: "tipd has a standard processing fee of 3.9% + $0.30 per successful transaction. This fee covers our processing costs and ensures a secure and reliable payment experience for both you and your supporters."
        },
        {
          question: "Can I set minimum or maximum tip amounts?",
          answer: "Yes! You can customize your tip presets to suggest amounts that make sense for your supporters. Supporters can also enter custom amounts within Stripe's supported range."
        }
      ]
    },
    {
      category: "Sending Tips",
      questions: [
        {
          question: "How do I find someone to tip?",
          answer: "Simply tap the NFC tag attached to someones TipdTech to launch the tipd app. Or, use the search bar on the homepage to find users by their username. Once you find the right profile, you can choose a tip amount and complete the payment with your card."
        },
        {
          question: "What payment methods are accepted?",
          answer: "We accept all major credit and debit cards through Stripe's secure payment processing. This includes Visa, Mastercard, American Express, and more."
        },
        {
          question: "Is my payment information secure?",
          answer: "Absolutely! We use Stripe for all payment processing, which means your card details are never stored on our servers and are handled with bank-level security."
        }
      ]
    },
    {
      category: "Account Management",
      questions: [
        {
          question: "How do I change my username or email?",
          answer: "Currently, usernames cannot be changed after account creation to maintain consistent links. To update your email, please contact our support team."
        },
        {
          question: "Can I delete my account?",
          answer: "Yes, you can delete your account at any time. Please note that this will remove your profile and you'll no longer be able to receive tips. Contact support to initiate account deletion."
        },
        {
          question: "What happens to my tips if I close my account?",
          answer: "Any pending tips will be processed normally to your connected Stripe account. Make sure to wait for all pending transactions to clear before closing your account."
        }
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white">
      <div className="max-w-3xl mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">Help Center</h1>
        
        <div className="space-y-6">
          {faqs.map((category, categoryIndex) => (
            <div key={categoryIndex} className="bg-white rounded-2xl shadow-lg p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">{category.category}</h2>
              <div className="space-y-2">
                {category.questions.map((faq, faqIndex) => (
                  <Disclosure key={faqIndex}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-3 text-left text-gray-900 bg-gray-50 hover:bg-gray-100 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-navy-500 focus-visible:ring-opacity-75">
                          <span className="font-medium">{faq.question}</span>
                          <ChevronDown
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-navy-500`}
                          />
                        </Disclosure.Button>
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-600">
                            {faq.answer}
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            </div>
          ))}

          <div className="bg-white rounded-2xl shadow-lg p-8 text-center">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Still need help?</h2>
            <p className="text-gray-600 mb-6">
              Can't find what you're looking for? We're here to help!
            </p>
            <a
              href="mailto:support@tipd.com"
              className="inline-block px-8 py-3 bg-navy-600 text-white rounded-lg hover:bg-navy-700 transition-colors"
            >
              Contact Support
            </a>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default HelpPage;