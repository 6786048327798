import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Search } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { collection, query, where, getDocs, QuerySnapshot, DocumentData } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import Logo2 from './Logo2';
import Footer from './Footer';

function HomePage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const searchUsers = async (username: string): Promise<QuerySnapshot<DocumentData>> => {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('username', '==', username));
    return getDocs(q);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const query = searchQuery.trim();
    
    if (!query) {
      setError('Please enter a username to search');
      return;
    }

    setIsSearching(true);
    setError('');

    try {
      // Try exact match first
      const exactResults = await searchUsers(query);
      
      if (!exactResults.empty) {
        const userData = exactResults.docs[0].data();
        navigate(`/${userData.username}`);
        return;
      }

      // Try lowercase match if exact match fails
      const lowercaseResults = await searchUsers(query.toLowerCase());
      
      if (!lowercaseResults.empty) {
        const userData = lowercaseResults.docs[0].data();
        navigate(`/${userData.username}`);
        return;
      }

      setError('User not found');
    } catch (error) {
      console.error('Search error:', error);
      setError('Something went wrong. Please try again later.');
    } finally {
      setIsSearching(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white flex flex-col">
      <div className="flex flex-col items-center justify-start pt-[100px] p-4">
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="flex justify-center mb-4"
        >
          <div className="w-[200px]" >
            <Logo2 />
          </div>
        </motion.div>

        <motion.form
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          onSubmit={handleSubmit}
          className="w-full max-w-md"
        >
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="search"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setError('');
              }}
              placeholder="Search by username.."
              aria-label="Search for a user by their username"
              className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 
                       shadow-lg focus:ring-2 focus:ring-navy-500 focus:border-transparent
                       placeholder:text-gray-400"
              disabled={isSearching}
            />
          </div>

          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                className="mt-3 p-3 bg-red-50 border border-red-200 rounded-lg"
              >
                <p className="text-sm text-red-600">{error}</p>
              </motion.div>
            )}
          </AnimatePresence>

          <motion.button
            type="submit"
            disabled={isSearching}
            className="w-full mt-4 py-3 px-4 rounded-lg bg-gradient-to-r from-navy-600 to-navy-700
                     text-white font-medium shadow-lg hover:from-navy-700 hover:to-navy-800
                     transition-all duration-200 disabled:opacity-70 disabled:cursor-not-allowed
                     flex items-center justify-center"
          >
            {isSearching ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Searching...
              </>
            ) : (
              'Search'
            )}
          </motion.button>
        </motion.form>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
          className="mt-8 text-center space-y-2"
        >
          {!user ? (
            <>
              <p className="text-gray-600">
                Want to get tipd?{' '}
                <Link to="/signup" className="text-navy-600 hover:text-navy-700 font-medium">
                  Sign up now
                </Link>
              </p>
              <p>
                <Link to="/login" className="text-gray-400 hover:text-gray-600 transition-colors">
                  Log In
                </Link>
              </p>
            </>
          ) : (
            <p>
              <button
                onClick={handleLogout}
                className="text-gray-400 hover:text-gray-600 transition-colors"
              >
                Log out
              </button>
            </p>
          )}
        </motion.div>
      </div>

      <div className="mt-24">
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;