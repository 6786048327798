import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { motion } from 'framer-motion';
import { Wallet2 } from 'lucide-react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

function StripeConnect() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleConnect = async () => {
    if (!user) {
      setError('Please log in to continue');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      // Store user ID in session storage for the success page
      sessionStorage.setItem('stripe_setup_user_id', user.uid);

      const response = await fetch('/.netlify/functions/connect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.uid,
          email: user.email,
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create Stripe account');
      }

      const { url, accountId } = await response.json();
      
      if (!accountId) {
        throw new Error('No Stripe account ID received');
      }

      // Save the Stripe account ID to Firebase
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        stripeAccountId: accountId,
        stripeOnboardingStatus: 'pending',
        updatedAt: new Date().toISOString()
      });

      if (url) {
        window.location.href = url;
      }
    } catch (error) {
      console.error('Error during Stripe connect:', error);
      setError(error instanceof Error ? error.message : 'An error occurred');
      sessionStorage.removeItem('stripe_setup_user_id');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full">
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={handleConnect}
        disabled={isLoading}
        className={`
          w-full py-3 px-4 rounded-lg
          bg-gradient-to-r from-navy-600 to-navy-700
          text-white font-medium shadow-lg
          flex items-center justify-center gap-2
          transition-all duration-200
          ${isLoading ? 'opacity-75 cursor-not-allowed' : 'hover:from-navy-700 hover:to-navy-800'}
        `}
      >
        {isLoading ? (
          <>
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span>Connecting to Stripe...</span>
          </>
        ) : (
          <>
            <Wallet2 className="w-5 h-5" />
            <span>Connect with Stripe</span>
          </>
        )}
      </motion.button>

      {error && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-4 p-3 bg-red-50 border border-red-200 rounded-lg"
        >
          <p className="text-sm text-red-600">{error}</p>
        </motion.div>
      )}
    </div>
  );
}

export default StripeConnect;
