import React from 'react';
// import { Wallet2 } from 'lucide-react'; // You can also comment out the import if it's not needed for now.

function Logo2() {
  return (
    <div className="flex items-center justify-center gap-2 hover:opacity-80 transition-opacity">
      {/* <Wallet2 className="w-6 h-6 text-navy-800 hover:text-teal-400 transition-all duration-300" /> */}
      <span className="text-2xl font-bold bg-gradient-to-r from-navy-600 to-navy-800 bg-clip-text text-transparent transition-all duration-300 hover:bg-gradient-to-l hover:from-teal-400 hover:to-blue-500">
        Who needs a tip?
      </span>
    </div>
  );
}

export default Logo2;
