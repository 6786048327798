import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';

function PaymentSuccessPage() {
  const [searchParams] = useSearchParams();
  const amount = searchParams.get('amount');
  const recipient = searchParams.get('recipient');
  const timestamp = searchParams.get('timestamp');
  const paymentId = searchParams.get('paymentId');

  const formattedDate = timestamp 
    ? new Date(timestamp).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    : '';

  return (
    <div className="max-w-2xl mx-auto px-4 py-12">
      <div className="bg-white rounded-lg shadow-sm p-8 text-center">
        <div className="flex justify-center mb-6">
          <CheckCircle className="w-16 h-16 text-green-500" />
        </div>
        <h1 className="text-2xl font-semibold text-gray-900 mb-4">
          Payment Successful!
        </h1>
        <div className="space-y-4 text-gray-600">
          <p className="text-lg">
            You've successfully sent <span className="font-semibold text-gray-900">${amount}</span> to{' '}
            <span className="font-semibold text-gray-900">{recipient}</span>
          </p>
          <p className="text-sm">
            Transaction completed on {formattedDate}
          </p>
          <p className="text-xs text-gray-500">
            Transaction ID: {paymentId}
            <br />
            Please save this information for your records
          </p>
        </div>
        <div className="mt-8">
          <a
            href="/"
            className="inline-block px-6 py-3 bg-navy-600 text-white rounded-lg hover:bg-navy-700 transition-colors"
          >
            Return Home
          </a>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccessPage;