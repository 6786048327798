import React from 'react';
import Footer from '../../components/Footer';

function TermsPage() {
  const sections = [
    {
      title: "1. Terms of Service Agreement",
      content: [
        {
          details: "By accessing or using tipd's services, you agree to be bound by these terms. Please read them carefully before using our platform.",
          subsections: [
            {
              subtitle: "1.1 Acceptance of Terms",
              text: "These terms govern your access to and use of tipd's services, including our website, mobile applications, and payment processing services."
            },
            {
              subtitle: "1.2 Changes to Terms",
              text: "We may modify these terms at any time. We'll notify you of material changes via email or through our platform. Your continued use of tipd after changes indicates acceptance of the modified terms."
            }
          ]
        }
      ]
    },
    {
      title: "2. Account Terms",
      content: [
        {
          details: "Your tipd account is subject to the following terms:",
          subsections: [
            {
              subtitle: "2.1 Account Registration",
              text: "You must provide accurate, complete information when registering. You're responsible for maintaining the security of your account credentials."
            },
            {
              subtitle: "2.2 Account Requirements",
              text: "You must be at least 18 years old and able to form legally binding contracts to use tipd. Business accounts must be authorized representatives of their organization."
            },
            {
              subtitle: "2.3 Account Restrictions",
              text: "You may not use tipd for illegal activities, fraudulent transactions, or in violation of these terms. We reserve the right to suspend or terminate accounts for violations."
            }
          ]
        }
      ]
    },
    {
      title: "3. Payment Terms",
      content: [
        {
          details: "Our payment processing services are subject to the following terms:",
          subsections: [
            {
              subtitle: "3.1 Payment Processing",
              text: "We use Stripe to process payments. By using tipd, you agree to Stripe's Services Agreement where applicable."
            },
            {
              subtitle: "3.2 Processing Fees",
              text: "Standard processing fees apply to all transactions (2.9% + $0.30 per successful transaction). Additional fees may apply for currency conversion or international transactions."
            },
            {
              subtitle: "3.3 Payouts",
              text: "Funds will be transferred to your connected bank account within 2-3 business days. Payout timing may vary based on your location and bank."
            },
            {
              subtitle: "3.4 Refunds and Chargebacks",
              text: "You're responsible for all chargebacks, refunds, and associated fees. We may withhold funds to cover potential chargebacks or disputes."
            }
          ]
        }
      ]
    },
    {
      title: "4. Platform Rules",
      content: [
        {
          details: "When using tipd, you agree to:",
          subsections: [
            {
              subtitle: "4.1 Prohibited Activities",
              text: "You may not use tipd for illegal activities, harassment, fraud, or any activities that violate our terms or applicable laws."
            },
            {
              subtitle: "4.2 Content Guidelines",
              text: "Your profile content must be appropriate and accurate. We reserve the right to remove content that violates our guidelines."
            },
            {
              subtitle: "4.3 Intellectual Property",
              text: "You retain rights to your content, but grant us license to use it for operating and improving our services."
            }
          ]
        }
      ]
    },
    {
      title: "5. Liability and Disputes",
      content: [
        {
          details: "Understanding our liability limitations and dispute resolution process:",
          subsections: [
            {
              subtitle: "5.1 Limitation of Liability",
              text: "tipd is not liable for indirect, incidental, special, or consequential damages arising from your use of our services."
            },
            {
              subtitle: "5.2 Dispute Resolution",
              text: "Any disputes will be resolved through binding arbitration, except where prohibited by law. You waive rights to participate in class actions."
            },
            {
              subtitle: "5.3 Governing Law",
              text: "These terms are governed by the laws of the State of California, without regard to conflict of law principles."
            }
          ]
        }
      ]
    },
    {
      title: "6. Service Modifications",
      content: [
        {
          details: "Our rights regarding service changes:",
          subsections: [
            {
              subtitle: "6.1 Service Availability",
              text: "We may modify, suspend, or discontinue any aspect of our services at any time. We'll provide reasonable notice for material changes."
            },
            {
              subtitle: "6.2 Feature Changes",
              text: "We may add, modify, or remove features at our discretion. We're not liable for any impact these changes may have on your use of tipd."
            }
          ]
        }
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white">
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4 text-center">Terms & Conditions</h1>
        <p className="text-gray-600 mb-8 text-center">Last updated: December 2024</p>
        
        <div className="prose prose-lg max-w-none">
          <p className="text-gray-600 mb-8">
            These terms of service ("Terms") govern your access to and use of tipd's services. 
            By using tipd, you agree to be bound by these Terms. If you don't agree to these Terms, 
            do not use tipd.
          </p>

          <div className="space-y-8">
            {sections.map((section, index) => (
              <section key={index} className="bg-white rounded-2xl shadow-lg p-8">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4">{section.title}</h2>
                {section.content.map((content, contentIndex) => (
                  <div key={contentIndex} className="mb-6 last:mb-0">
                    <p className="text-gray-600 mb-4">{content.details}</p>
                    <div className="space-y-4">
                      {content.subsections.map((subsection, subIndex) => (
                        <div key={subIndex}>
                          <h3 className="text-lg font-medium text-gray-900 mb-2">
                            {subsection.subtitle}
                          </h3>
                          <p className="text-gray-600">{subsection.text}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </section>
            ))}
          </div>

          <div className="bg-white rounded-2xl shadow-lg p-8 mt-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Contact Us</h2>
            <p className="text-gray-600 mb-4">
              If you have any questions about these Terms, please contact us at:{' '}
              <a 
                href="mailto:legal@tipd.com" 
                className="text-navy-600 hover:text-navy-700"
              >
                legal@tipd.com
              </a>
            </p>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default TermsPage;