import React from 'react';
import { Wallet2 } from 'lucide-react';

function Logo() {
  return (
    <div className="flex items-center justify-center gap-2 hover:opacity-80 transition-opacity">
      <Wallet2 className="w-6 h-6 text-navy-600" />
      <span className="text-2xl font-bold bg-gradient-to-r from-navy-600 to-navy-800 bg-clip-text text-transparent">
        tipd
      </span>
    </div>
  );
}

export default Logo;