import React from 'react';
import { motion } from 'framer-motion';

interface TipInputProps {
  value: number | null;
  onChange: (value: number | null) => void;
  presets?: number[];
}

function TipInput({ value, onChange, presets = [2, 5, 10, 20] }: TipInputProps) {
  const [customValue, setCustomValue] = React.useState('');
  const [isCustom, setIsCustom] = React.useState(false);

  const handleCustomValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setCustomValue(newValue);
    setIsCustom(true);
    
    if (newValue === '') {
      setCustomValue('');
      onChange(null);
      return;
    }

    const numericValue = Number(newValue);
    if (!isNaN(numericValue)) {
      onChange(numericValue);
    }
  };

  const handlePresetClick = (amount: number) => {
    setIsCustom(false);
    setCustomValue('');
    onChange(amount);
  };

  return (
    <div className="space-y-4">
      <label className="block text-sm font-medium text-gray-700">Select Tip Amount</label>
      
      <div className="grid grid-cols-4 gap-2">
        {presets.map((amount, index) => (
          <motion.button
            key={amount}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: index * 0.1 }}
            onClick={() => handlePresetClick(amount)}
            className={`
              py-2 px-4 rounded-lg font-medium transition-all
              ${!isCustom && value === amount
                ? 'bg-navy-600 text-white shadow-lg scale-105'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }
            `}
          >
            ${amount}
          </motion.button>
        ))}
      </div>

      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Custom Amount
        </label>
        <div className="relative">
          <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">$</span>
          <input
            type="number"
            value={customValue}
            onChange={handleCustomValueChange}
            className="w-full pl-8 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-navy-500 focus:border-transparent"
            min="0"
            step="1"
            placeholder="Enter amount"
          />
        </div>
      </div>
    </div>
  );
}

export default TipInput;