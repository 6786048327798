import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';

function AboutPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white">
      <div className="max-w-3xl mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">About tipd</h1>
        
        <div className="space-y-8">
          <section className="bg-white rounded-2xl shadow-lg p-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">What is tipd?</h2>
            <p className="text-gray-600 leading-relaxed">
              tipd is a simple and secure platform that lets you receive tips from anyone who wants to support your work. 
              Whether you're a creator, freelancer, or someone who helps others, tipd makes it easy for people to show their appreciation.
            </p>
          </section>

          <section className="bg-white rounded-2xl shadow-lg p-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">How it works</h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">For Tip Recipients</h3>
                <ol className="list-decimal list-inside text-gray-600 leading-relaxed pl-4">
                  <li className="mb-2">Sign up for a free tipd account</li>
                  <li className="mb-2">Complete the simple Stripe payment setup</li>
                  <li className="mb-2">Customize your profile with your photo and bio</li>
                  <li>Share your unique tipd link with your supporters</li>
                </ol>
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">For Supporters</h3>
                <ol className="list-decimal list-inside text-gray-600 leading-relaxed pl-4">
                  <li className="mb-2">Search for someone by their username</li>
                  <li className="mb-2">Choose a tip amount or enter your own</li>
                  <li>Complete the secure payment with your card</li>
                </ol>
              </div>
            </div>
          </section>

          <section className="bg-white rounded-2xl shadow-lg p-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Why choose tipd?</h2>
            <ul className="space-y-3 text-gray-600 leading-relaxed">
              <li className="flex items-start">
                <span className="text-navy-600 mr-2">•</span>
                <span><strong>Simple:</strong> Clean interface with no unnecessary features</span>
              </li>
              <li className="flex items-start">
                <span className="text-navy-600 mr-2">•</span>
                <span><strong>Secure:</strong> Powered by Stripe's trusted payment processing</span>
              </li>
              <li className="flex items-start">
                <span className="text-navy-600 mr-2">•</span>
                <span><strong>Direct:</strong> Tips go straight to your Stripe account</span>
              </li>
              <li className="flex items-start">
                <span className="text-navy-600 mr-2">•</span>
                <span><strong>Customizable:</strong> Set your own tip presets and profile details</span>
              </li>
            </ul>
          </section>

          <div className="text-center">
            <Link
              to="/signup"
              className="inline-block px-8 py-3 bg-navy-600 text-white rounded-lg hover:bg-navy-700 transition-colors"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default AboutPage;
