import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ProfilePage from './components/ProfilePage';
import HomePage from './components/HomePage';
import SignUpPage from './components/pages/SignUpPage';
import LoginPage from './components/pages/LoginPage';
import AboutPage from './components/pages/AboutPage';
import TermsPage from './components/pages/TermsPage';
import PrivacyPage from './components/pages/PrivacyPage';
import HelpPage from './components/pages/HelpPage';
import ContactPage from './components/pages/ContactPage';
import PaymentSetupPage from './components/pages/PaymentSetupPage';
import PaymentSetupSuccessPage from './components/pages/PaymentSetupSuccessPage';
import Header from './components/Header';
import ScrollToTop from './components/ScrollToTop';
import { PaymentProvider } from './contexts/PaymentContext';
import { AuthProvider } from './contexts/AuthContext';
import PaymentSuccessPage from './components/PaymentSuccessPage';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Elements stripe={stripePromise}>
          <PaymentProvider>
            <ScrollToTop />
            <div className="min-h-screen flex flex-col bg-gradient-to-b from-navy-50 to-white">
              <Header />
              <main className="flex-1 flex flex-col">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/signup" element={<SignUpPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/terms" element={<TermsPage />} />
                  <Route path="/privacy" element={<PrivacyPage />} />
                  <Route path="/help" element={<HelpPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/payment-setup" element={<PaymentSetupPage />} />
                  <Route path="/payment-setup/success" element={<PaymentSetupSuccessPage />} />
                  <Route path="/:username" element={<ProfilePage />} />
                  <Route path="/payment/success" element={<PaymentSuccessPage />} />
                </Routes>
              </main>
            </div>
          </PaymentProvider>
        </Elements>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
