import React from 'react';
import Footer from '../../components/Footer';

function PrivacyPage() {
  const sections = [
    {
      title: "Information We Collect",
      content: [
        {
          subtitle: "Account Information",
          details: "When you create a tipd account, we collect:",
          items: [
            "Email address",
            "Username",
            "Password (encrypted)",
            "Profile information (optional: name, bio, profile picture)",
          ]
        },
        {
          subtitle: "Payment Information",
          details: "For payment processing, we collect:",
          items: [
            "Payment method details (processed securely through Stripe)",
            "Transaction history",
            "Billing information",
          ]
        },
        {
          subtitle: "Usage Information",
          details: "We automatically collect:",
          items: [
            "IP address",
            "Browser type and version",
            "Device information",
            "Usage patterns and preferences",
          ]
        }
      ]
    },
    {
      title: "How We Use Your Information",
      content: [
        {
          details: "We use your information to:",
          items: [
            "Process payments and transfers",
            "Provide and improve our services",
            "Communicate with you about your account",
            "Prevent fraud and ensure security",
            "Comply with legal obligations",
            "Analyze and improve platform performance"
          ]
        }
      ]
    },
    {
      title: "Information Sharing",
      content: [
        {
          details: "We share your information with:",
          items: [
            "Stripe (our payment processor) for payment processing",
            "Service providers who assist in our operations",
            "Law enforcement when required by law",
          ]
        },
        {
          details: "We do NOT:",
          items: [
            "Sell your personal information",
            "Share your data with third-party advertisers",
            "Use your information for purposes other than those specified",
          ]
        }
      ]
    },
    {
      title: "Data Security",
      content: [
        {
          details: "We protect your data through:",
          items: [
            "Encryption of sensitive information",
            "Regular security audits and updates",
            "Secure data storage and transmission",
            "Limited employee access to user data",
            "Strict data handling procedures"
          ]
        }
      ]
    },
    {
      title: "Your Rights",
      content: [
        {
          details: "You have the right to:",
          items: [
            "Access your personal information",
            "Correct inaccurate data",
            "Request deletion of your data",
            "Export your data",
            "Opt-out of non-essential communications",
            "Lodge a complaint with supervisory authorities"
          ]
        }
      ]
    },
    {
      title: "Data Retention",
      content: [
        {
          details: "We retain your information:",
          items: [
            "As long as your account is active",
            "As required by law and regulations",
            "As needed for fraud prevention and security"
          ]
        }
      ]
    },
    {
      title: "Updates to Privacy Policy",
      content: [
        {
          details: "We may update this policy:",
          items: [
            "To reflect changes in our practices",
            "To comply with new regulations",
            "To improve clarity and accuracy"
          ]
        },
        {
          details: "When we make changes:",
          items: [
            "We'll notify you via email for significant changes",
            "We'll update the 'last modified' date",
            "We'll seek consent when required by law"
          ]
        }
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-navy-50 to-white">
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4 text-center">Privacy Policy</h1>
        <p className="text-gray-600 mb-8 text-center">Last updated: December 2024</p>
        
        <div className="prose prose-lg max-w-none">
          <p className="text-gray-600 mb-8">
            At tipd, we take your privacy seriously. This policy explains how we collect, use, and protect your personal information. 
            By using tipd, you agree to the collection and use of information in accordance with this policy.
          </p>

          <div className="space-y-8">
            {sections.map((section, index) => (
              <section key={index} className="bg-white rounded-2xl shadow-lg p-8">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4">{section.title}</h2>
                {section.content.map((content, contentIndex) => (
                  <div key={contentIndex} className="mb-6 last:mb-0">
                    {content.subtitle && (
                      <h3 className="text-xl font-medium text-gray-900 mb-2">
                        {content.subtitle}
                      </h3>
                    )}
                    <p className="text-gray-600 mb-2">{content.details}</p>
                    <ul className="list-disc list-inside text-gray-600 space-y-1">
                      {content.items.map((item, itemIndex) => (
                        <li key={itemIndex} className="ml-4">{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </section>
            ))}
          </div>

          <div className="bg-white rounded-2xl shadow-lg p-8 mt-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Contact Us</h2>
            <p className="text-gray-600 mb-4">
              If you have any questions about this Privacy Policy, please contact us at:{' '}
              <a 
                href="mailto:privacy@tipd.com" 
                className="text-navy-600 hover:text-navy-700"
              >
                privacy@tipd.com
              </a>
            </p>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default PrivacyPage;