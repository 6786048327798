import React, { createContext, useContext, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { doc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface PaymentContextType {
  processPayment: (params: PaymentParams) => Promise<{ error?: Error }>;
  isProcessing: boolean;
  error: string | null;
}

interface PaymentParams {
  amount: number;
  currency: string;
  description: string;
  recipientId: string;
  paymentMethod?: string;
}

const PaymentContext = createContext<PaymentContextType | undefined>(undefined);

export function PaymentProvider({ children }: { children: React.ReactNode }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const stripe = useStripe();
  const elements = useElements();

  const processPayment = async (params: PaymentParams) => {
    if (!stripe || !elements) {
      const error = new Error('Payment system not initialized');
      setError(error.message);
      return { error };
    }

    setIsProcessing(true);
    setError(null);

    try {
      console.log('Creating payment intent with params:', {
        amount: params.amount,
        currency: params.currency,
        description: params.description,
        recipientId: params.recipientId
      });

      const response = await fetch('/.netlify/functions/payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amount: params.amount,
          currency: params.currency,
          description: params.description,
          recipientId: params.recipientId
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Payment intent creation failed:', errorData);
        throw new Error(errorData.error || 'Payment initialization failed');
      }

      const { clientSecret } = await response.json();

      if (!clientSecret) {
        throw new Error('No client secret received');
      }

      // Use the PaymentElement for confirmation
      const { error: confirmError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment/success`,
        },
        redirect: 'if_required',
      });

      if (confirmError) {
        throw confirmError;
      }

      if (paymentIntent?.status === 'succeeded') {
        const recipientRef = doc(db, 'users', params.recipientId);
        await updateDoc(recipientRef, {
          'financialData.availableBalance': increment(params.amount / 100),
          'financialData.stats.tipCount': increment(1),
          'financialData.stats.lastTipReceived': new Date().toISOString(),
        });
      }

      return {};
    } catch (error) {
      console.error('Payment processing error:', error);
      const errorMessage = error instanceof Error ? error.message : 'Payment failed';
      setError(errorMessage);
      return { error: new Error(errorMessage) };
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <PaymentContext.Provider value={{ processPayment, isProcessing, error }}>
      {children}
    </PaymentContext.Provider>
  );
}

export function usePayment() {
  const context = useContext(PaymentContext);
  if (context === undefined) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }
  return context;
}
