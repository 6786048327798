import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

interface OtherPaymentsProps {
  venmoUsername?: string;
  cashappId?: string;
  paypalEmail?: string;
  tipAmount?: number;
}

function OtherPayments({ venmoUsername, cashappId, paypalEmail, tipAmount }: OtherPaymentsProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const hasAnyPaymentOption = venmoUsername || cashappId || paypalEmail;

  if (!hasAnyPaymentOption) return null;

  const encodedNote = encodeURIComponent("payment for service provided");

  return (
    <div className="mt-4">
      {/* Divider */}
      <div className="w-full h-px bg-gray-200 mb-4"></div>

      {/* Toggle Button */}
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
      >
        <span className="text-sm">other payment options</span>
        <motion.div
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <ChevronDown className="w-4 h-4" />
        </motion.div>
      </button>

      {/* Payment Options */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="grid gap-3 mt-4">
              {venmoUsername && (
                <a
                  href={`venmo://paycharge?txn=pay&recipients=${venmoUsername}&amount=${tipAmount || ''}&note=${encodedNote}`}
                  onClick={(e) => {
                    e.preventDefault();
                    const appUrl = `venmo://paycharge?txn=pay&recipients=${venmoUsername}&amount=${tipAmount || ''}&note=${encodedNote}`;
                    const webUrl = `https://venmo.com/${venmoUsername}?txn=pay&amount=${tipAmount || ''}&note=${encodedNote}`;
                    
                    // Track if we're leaving the page
                    let appWasOpened = false;
                    window.addEventListener('blur', () => {
                      appWasOpened = true;
                    }, { once: true });

                    // Try to open the app
                    window.location.href = appUrl;
                    
                    // Fallback to web after delay, only if app wasn't opened
                    setTimeout(() => {
                      if (!appWasOpened) {
                        window.location.href = webUrl;
                      }
                    }, 500);
                  }}
                  className="flex items-center justify-center px-4 py-3 rounded-lg bg-[#3D95CE] text-white hover:opacity-90 transition-opacity"
                >
                  Pay with Venmo
                </a>
              )}

              {cashappId && (
                <a
                  href={`https://cash.app/$${cashappId}/${tipAmount || ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    const universalUrl = `https://cash.app/$${cashappId}/${tipAmount || ''}`;
                    
                    // Track if we're leaving the page
                    let appWasOpened = false;
                    window.addEventListener('blur', () => {
                      appWasOpened = true;
                    }, { once: true });

                    // Try to open the URL (will open app if installed)
                    window.location.href = universalUrl;
                    
                    // No fallback needed since the URL works for both app and web
                  }}
                  className="flex items-center justify-center px-4 py-3 rounded-lg bg-[#00D632] text-white hover:opacity-90 transition-opacity"
                >
                  Pay with Cash App
                </a>
              )}

              {paypalEmail && (
                <a
                  href={`https://paypal.me/${paypalEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center px-4 py-3 rounded-lg bg-[#003087] text-white hover:opacity-90 transition-opacity"
                >
                  Pay with PayPal
                </a>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default OtherPayments;
